import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"

const ProductsPage = ({ data }) => {
  // Log the data to ensure products are fetched correctly
  console.log(data.allSanityMerchandise.nodes)

  const products = data.allSanityMerchandise.nodes

  // Filter out products that have an _id starting with "drafts."
  const publishedProducts = products.filter(
    product => !product._id.startsWith("drafts.")
  )

  // State setup
  const [query, setQuery] = useState("")
  const [selectedFamilies, setSelectedFamilies] = useState([])
  const [filteredProducts, setFilteredProducts] = useState(publishedProducts) // Start with published products
  const [isCategoriesVisible, setIsCategoriesVisible] = useState(false)

  // Extract unique product families for checkboxes
  const productFamilies = [
    ...new Set(publishedProducts.map(p => p.productFamily.title)),
  ]

  // Update filtered products based on search query and selected categories
  useEffect(() => {
    const filtered = publishedProducts.filter(
      product =>
        product.title.toLowerCase().includes(query) &&
        (selectedFamilies.length === 0 ||
          selectedFamilies.includes(product.productFamily.title))
    )
    setFilteredProducts(filtered)
  }, [query, selectedFamilies]) // Only depend on query and selectedFamilies

  // Search query handler
  const handleSearch = event => {
    setQuery(event.target.value.toLowerCase())
  }

  // Handle category filtering
  const handleFamilyFilter = (family, isChecked) => {
    const newSelectedFamilies = isChecked
      ? [...selectedFamilies, family]
      : selectedFamilies.filter(f => f !== family)
    setSelectedFamilies(newSelectedFamilies)
  }

  const Hit = ({ product }) => {
    const { title, mainImage, price, excerpt, slug } = product
    return (
      <div className="flex flex-col justify-between p-4 border dark:border-zinc-700 rounded-md h-full  bg-white dark:bg-zinc-800">
        <a href={`/store/${slug.current}`} className="flex-grow">
          <div
            className="bg-cover bg-center h-40"
            style={{
              backgroundImage: `url(${
                mainImage?.asset?.url || "/default-image.png"
              })`,
            }}
          ></div>
          <h5 className="text-sm font-bold mt-2">{title}</h5>
          <p className="text-xs font-normal mt-2 flex-grow">{excerpt}</p>
        </a>
        <div className="text-indigo-600 font-semibold text-xs">
          {price ? `$${price}` : "N/A"}
        </div>
      </div>
    )
  }

  return (
    <div className=" text-zinc-900 dark:text-zinc-200  bg-white dark:bg-zinc-900">
      <Seo
        title={"Imagination Outfitters"}
        description={
          "Rugged, innovative gear and materials for the modern creative explorer."
        }
        defer={false}
      />
      <div className="container mx-auto p-6 ">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search products"
            value={query}
            onChange={handleSearch}
            className="border p-2 rounded w-full dark:border-zinc-700 bg-white dark:bg-zinc-900"
          />

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
            <div>
              {/* Toggle button for categories */}
              <button
                onClick={() => setIsCategoriesVisible(!isCategoriesVisible)}
                className="md:hidden border-zinc-400 border-2 rounded px-3 py-2 text-xxs font-bold bspace"
              >
                {isCategoriesVisible ? "Hide Filters" : "Show Filters"}
              </button>
              <div
                className={`${
                  isCategoriesVisible ? "block" : "hidden"
                } md:block`}
              >
                <span className="text-xxs tracking-widest">CATEGORIES: </span>
                {productFamilies.map(family => (
                  <label
                    key={family}
                    className="flex flex-inline items-left items-center mt-3"
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox h-2 w-2 mr-1"
                      value={family}
                      onChange={e =>
                        handleFamilyFilter(family, e.target.checked)
                      }
                    />
                    <span className="mr-4 text-xs">{family}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="grid col-span-3 grid-cols-1 md:grid-cols-3 gap-4">
              {filteredProducts.map(product => (
                <Hit key={product.id} product={product} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsPage

export const query = graphql`
  query ProductQuery {
    allSanityMerchandise {
      nodes {
        _id
        id
        title
        slug {
          current
        }
        mainImage {
          asset {
            url
          }
        }
        price
        excerpt
        productFamily {
          title
        }
      }
    }
  }
`
